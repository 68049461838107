import { MedicalUnit } from '@sancare/ui-frontend-commons/src/store/modules/filters/medical-unit-filter/types'
import { Dayjs } from 'dayjs'

import { ModeEnum, PmsiTypeEnum, PrivacyEnum, User } from '@/store/modules/settings/types'

type SavedSearchUser = Pick<User, 'id' | 'username' | 'team'>

/**
 * Used when search is being created or is unknown
 */
export const DEFAULT_SEARCH_ID = -1

export type ListedSavedSearch = {
  id: number
  reference: string
  name: string
  searchOperators: SearchOperatorEnum
  isAdvancedSearch: boolean
  countTodoStay: number
  privacy: PrivacyEnum
  savedSearchCategory: SavedSearchCategory
  user: SavedSearchUser
  validated: boolean
  disabled: boolean
  toBeRemoved: boolean
  type: ModeEnum
  pmsiType: PmsiTypeEnum
}

export enum SearchOperatorEnum {
  AND_OR = 'andOr',
  OR_AND = 'orAnd',
}

export type SavedSearchCategory = {
  id: number | null
  name: string
}

export type StoredSavedSearchCategory = SavedSearchCategory & {
  countStay: number
  countTodoStay: number
}

export interface SavedSearchesPayload {
  mode?: string
  earlyLimit: Dayjs
  lateLimit: Dayjs
  unit?: string
  medicalUnits: MedicalUnit[] | null
  pmsiType?: PmsiTypeEnum
  searchMode?: ModeEnum
  searchIds?: number[]
}
