import _ from 'lodash'

/**
 * Takes a suggestedDiagnosis object as parameter
 * Returns the correct chunk object, depending on the origin of the suggestion
 */
function extractChunksFromSuggestedDiagnosis(suggestedDiagnosis, savedSearchIntersectionChunks) {
  if (suggestedDiagnosis.prediction) {
    // If there is a predictedDiagnosis, the chunks correspond to the hints but also the all the matched savedSearches.
    // This was calculated with computeSingleLabelChunks (from splitReportContent.js) in the store.
    return suggestedDiagnosis.prediction.chunks
  } else if (suggestedDiagnosis.staySavedSearches && suggestedDiagnosis.staySavedSearches.length === 1) {
    // If there is only one, we find the matching search.
    const staySavedSearch = suggestedDiagnosis.staySavedSearches[0]
    return staySavedSearch ? staySavedSearch.chunks : null
  } else if (suggestedDiagnosis.staySavedSearches && suggestedDiagnosis.staySavedSearches.length > 1) {
    // If there are 2 searches or more, we must find the chunk list that corresponds to their intersection.
    // It was calculated with computeSearchIntersectionChunks (from splitReportContent.js) in the store.
    const intersec = _.find(savedSearchIntersectionChunks, (intersectionChunk) => {
      return _.isEqual(intersectionChunk.searchIds, _.sortBy(_.map(suggestedDiagnosis.staySavedSearches, (ss) => ss.search.id)))
    })
    return intersec ? intersec.chunks : null
  }

  return null
}

/**
  * Takes a chunk object as parameter
  * Returns an array of justification objects which contain minimal required data
  * for backend to log them in database (docType, docId, indices)
  *
  * Notes: indices is an array of 2-element arrays (integer pairs)
  */
function extractIndicesFromChunkObject(chunks) {
  if (!chunks) {
    return null
  }

  const justifications = []
  const docTypes = Object.keys(chunks)
  for (const docType of docTypes) {
    for (const [docId, chunkList] of Object.entries(chunks[docType])) {
      if (chunkList === true) {
        justifications.push({
          docType: docType,
          docId,
          indices: null,
        })
      } else if (typeof(chunkList) === 'object' && chunkList !== null) {
        justifications.push({
          docType: docType,
          docId,
          indices: _.map(_.pickBy(chunkList, 'isHighlighted'), (c) => [c.chunkStart, c.chunkEnd]),
        })
      }
    }
  }

  return justifications
}

export {
  extractChunksFromSuggestedDiagnosis,
  extractIndicesFromChunkObject
}
