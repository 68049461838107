import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

import { GroupingError } from '@/store/modules/stay/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'GroupingErrorMessage',
  props: {
    error: {},
    isCritical: { type: [Boolean, null] }
  },
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  return (_ctx.error)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass([_ctx.isCritical ? 'ghm-error' : 'ghm-warning', "text-center"])
      }, [
        _createElementVNode("span", null, "[" + _toDisplayString(_ctx.error.code) + "] " + _toDisplayString(_ctx.error.description), 1)
      ], 2))
    : _createCommentVNode("", true)
}
}

})