import { defineComponent as _defineComponent } from 'vue'
import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = { class: "findbar row align-items-center" }
const _hoisted_2 = { class: "col-8" }
const _hoisted_3 = ["placeholder"]
const _hoisted_4 = {
  key: 0,
  class: "stay-search-list"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "stay-search-list-title" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 2 }
const _hoisted_10 = { class: "stay-search-list-caption row" }
const _hoisted_11 = { class: "col" }
const _hoisted_12 = { class: "col-auto" }
const _hoisted_13 = { class: "col-auto" }
const _hoisted_14 = { class: "col-auto col-sm-4" }
const _hoisted_15 = ["disabled"]

import dayjs from '@sancare/ui-frontend-commons/src/misc/dayjs'
import _ from 'lodash'
import { computed, onMounted, ref, watch } from 'vue'

import { stay } from '@/stay-displayer/types'
import { useStore } from '@/store'


export default /*@__PURE__*/_defineComponent({
  __name: 'FindBar',
  props: {
    defaultSearchValue: { default: '' }
  },
  emits: ['handle-submit', 'handle-click'],
  setup(__props: any, { emit: __emit }) {

const store = useStore()
const emit = __emit

const props = __props

const searchOngoing = ref(false)
const enterPressed = ref(false)
const displaySearch = ref(false)
const searchInput = ref('')

const pmsiType = computed(() => store.getters.pmsiType)

const searchInputPlaceHolder = computed(() => {
  if (_.isEmpty(props.defaultSearchValue) || displaySearch.value) {
    return 'N°séjour, N°patient...'
  }

  return props.defaultSearchValue
})
const searchResults = computed(() => store.state.stayDisplayer.searchStayResults)

onMounted(() => {
  if (_.isEmpty(props.defaultSearchValue)) {
    return
  }

  searchInput.value = props.defaultSearchValue
})

watch(searchInput, () => handleChangeSearch())

const throttleSearch = _.throttle(async function(newSearchInput) {
  if (!newSearchInput) {
    store.commit('clearSearchStay')
    searchOngoing.value = false

    return
  }
  await store.dispatch('searchStay', { search: newSearchInput, pmsiType: store.getters.pmsiType })
  if (enterPressed.value) {
    handleSubmit()
  }
  searchOngoing.value = false
}, 500, { leading: false, maxWait: 500, trailing: true })
function handleChangeSearch() {
  searchOngoing.value = true
  throttleSearch(searchInput.value)
}
function handleSubmit() {
  emit('handle-submit', { ...getFinalResult() })
}
function inputFocus() {
  displaySearch.value = true
  if (searchInput.value) {
    store.dispatch('searchStay', searchInput.value)
  }
}
function inputTyped(event) {
  if (event.key === 'Enter') {
    enterPressed.value = true
    // We take the first suggested element if there are some, or we take the search as is.
    if (!searchOngoing.value) {
      handleSubmit()
    }

    return
  }

  if (event.key === 'Escape') {
    store.commit('clearSearchStay')
    displaySearch.value = false

    return
  }

  enterPressed.value = false
  displaySearch.value = true
}
function handleClick(stay: stay) {
  displaySearch.value = false
  emit('handle-click', { ...getFinalResult(stay) })
}
function getFinalResult(stay?: stay) {
  const firstResult = searchResults.value.length ? searchResults.value[0] : {}
  const result = _.isEmpty(stay) ? firstResult : stay

  return {
    result: result,
    input: searchInput.value,
  }
}
function hideDisplaySearch() {
  displaySearch.value = false
}
function dateFormat(date, frmt) {
  return dayjs(date).format(frmt)
}

return (_ctx: any,_cache: any) => {
  const _directive_on_click_outside = _resolveDirective("on-click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        ref: "input",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchInput).value = $event)),
        placeholder: searchInputPlaceHolder.value,
        class: "form-control sancare-search",
        type: "text",
        onKeyup: inputTyped,
        onFocus: inputFocus
      }, null, 40, _hoisted_3), [
        [_vModelText, searchInput.value]
      ]),
      (displaySearch.value && searchResults.value.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(searchResults.value, (searchResultsStay) => {
              return (_openBlock(), _createElementBlock("div", {
                key: `${searchResultsStay.id} - ${searchResultsStay.hasInitialCoding}`,
                class: "stay-search-list-item",
                onClick: ($event: any) => (handleClick(searchResultsStay))
              }, [
                _createElementVNode("div", _hoisted_6, [
                  (pmsiType.value === 'mco')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        (searchResultsStay.rssId)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_7, "RSS N°" + _toDisplayString(searchResultsStay.rssId) + " - ", 1))
                          : _createCommentVNode("", true)
                      ], 64))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        (searchResultsStay.ssrId)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_8, "SSR N°" + _toDisplayString(searchResultsStay.ssrId) + " - ", 1))
                          : _createCommentVNode("", true)
                      ], 64)),
                  (searchResultsStay.administrativeLocalStayId)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_9, "N° Administratif " + _toDisplayString(searchResultsStay.administrativeLocalStayId), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, _toDisplayString(searchResultsStay.patient.firstName) + " " + _toDisplayString(searchResultsStay.patient.lastName || searchResultsStay.patient.maidenName), 1),
                  _createElementVNode("div", _hoisted_12, _toDisplayString(searchResultsStay.stayDuration) + "J ", 1),
                  _createElementVNode("div", _hoisted_13, " Le " + _toDisplayString(dateFormat(searchResultsStay.stayStart, 'L')), 1)
                ])
              ], 8, _hoisted_5))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ])), [
      [_directive_on_click_outside, hideDisplaySearch]
    ]),
    _createElementVNode("div", _hoisted_14, [
      _createElementVNode("button", {
        disabled: !searchInput.value || !searchInput.value.length,
        type: "button",
        class: "btn btn-push btn-primary btn-fluid",
        onClick: handleSubmit
      }, " Rechercher ", 8, _hoisted_15)
    ])
  ]))
}
}

})