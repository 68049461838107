import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 3 }
const _hoisted_5 = { key: 4 }
const _hoisted_6 = { key: 5 }
const _hoisted_7 = { key: 6 }
const _hoisted_8 = { key: 7 }
const _hoisted_9 = { key: 8 }
const _hoisted_10 = { key: 9 }
const _hoisted_11 = { key: 10 }
const _hoisted_12 = { key: 11 }
const _hoisted_13 = { key: 12 }
const _hoisted_14 = { key: 13 }
const _hoisted_15 = ["title"]
const _hoisted_16 = { key: 15 }
const _hoisted_17 = { key: 16 }
const _hoisted_18 = { key: 17 }
const _hoisted_19 = ["title"]
const _hoisted_20 = { class: "freetext-wrapper" }
const _hoisted_21 = ["checked"]

import { computed } from 'vue'

import { statusLabels } from '@/misc/constants'
import { formatCost, formatGroupingCost } from '@/stay-list/stay-list-table/helpers/line/lineContentFormatters'
import { ExternalPmsiStatusLabel } from '@/stay-list/stay-list-table/helpers/line/lineHelper'
import useStayListLine from '@/stay-list/stay-list-table/line/composables/stayListLine'
import useStayRedirection from '@/stay-list/stay-list-table/line/composables/stayRedirection'
import NotificationLineColumn from '@/stay-list/stay-list-table/line/NotificationLineColumn.vue'
import { ListSsrStay } from '@/stay-list/stay-list-table/ssr/types'
import { StayListLineProps } from '@/stay-list/stay-list-table/types'
import { SeenStatusEnum } from '@/store/modules/stay/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'SsrStayListLine',
  props: {
    stay: {},
    columns: {},
    allowSelection: { type: Boolean },
    selected: { type: Boolean },
    mode: {}
  },
  emits: ["toggle-selected-stay"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const {
  patientName,
  staySeen,
  stayStart,
  stayEnd,
  validationDate,
  moneyGap,
  displayedSeenStatus,
  searches,
} = useStayListLine(props.stay)

const firstValue = computed(() => formatCost(props.stay.firstStandardModeGroupingResult.value))
const currentValue = computed(() => formatGroupingCost(props.stay.standardModeGroupingResult.gme !== null, props.stay.standardModeGroupingResult.value))
const rhsCount = computed(() => props.stay.rhsCount)
const initialGme = computed(() => props.stay.firstStandardModeGroupingResult.gme?.reference)
const currentGme = computed(() => props.stay.standardModeGroupingResult.gme?.reference)

const { viewStay } = useStayRedirection(`/ssr/${props.stay.id}`, props.mode)

const emit = __emit

function toggleSelectedStay() {
  emit('toggle-selected-stay', props.stay.id)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("tr", {
    class: _normalizeClass([{
      'unseen-stay': _unref(staySeen).statusValue >= _unref(SeenStatusEnum).UNSEEN,
      'selected': _ctx.selected
    }, "stay-list-line text-center align-items-center"]),
    name: "stayList_line",
    onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(viewStay)($event))),
    onMouseup: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_unref(viewStay)($event, true)), ["middle"]))
  }, [
    _createVNode(NotificationLineColumn, {
      displayed: 'notification' in _ctx.columns,
      "stay-seen": _unref(staySeen)
    }, null, 8, ["displayed", "stay-seen"]),
    (_ctx.columns.ssrId)
      ? (_openBlock(), _createElementBlock("td", _hoisted_1, _toDisplayString(_ctx.stay.ssrId), 1))
      : _createCommentVNode("", true),
    (_ctx.columns.administrativeLocalStayId)
      ? (_openBlock(), _createElementBlock("td", _hoisted_2, _toDisplayString(_ctx.stay.administrativeLocalStayId), 1))
      : _createCommentVNode("", true),
    (_ctx.columns.patientName)
      ? (_openBlock(), _createElementBlock("td", _hoisted_3, _toDisplayString(_unref(patientName)), 1))
      : _createCommentVNode("", true),
    (_ctx.columns.firstValue)
      ? (_openBlock(), _createElementBlock("td", _hoisted_4, [
          _createElementVNode("span", null, _toDisplayString(firstValue.value), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.columns.currentValue)
      ? (_openBlock(), _createElementBlock("td", _hoisted_5, [
          _createElementVNode("span", null, _toDisplayString(currentValue.value), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.columns.initialGme)
      ? (_openBlock(), _createElementBlock("td", _hoisted_6, _toDisplayString(initialGme.value), 1))
      : _createCommentVNode("", true),
    (_ctx.columns.currentGme)
      ? (_openBlock(), _createElementBlock("td", _hoisted_7, _toDisplayString(currentGme.value), 1))
      : _createCommentVNode("", true),
    (_ctx.columns.moneyGap)
      ? (_openBlock(), _createElementBlock("td", _hoisted_8, [
          _createElementVNode("span", null, _toDisplayString(_unref(moneyGap)), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.columns.rhsCount)
      ? (_openBlock(), _createElementBlock("td", _hoisted_9, [
          _createElementVNode("span", null, _toDisplayString(rhsCount.value), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.columns.stayStart)
      ? (_openBlock(), _createElementBlock("td", _hoisted_10, _toDisplayString(_unref(stayStart)), 1))
      : _createCommentVNode("", true),
    (_ctx.columns.stayEnd)
      ? (_openBlock(), _createElementBlock("td", _hoisted_11, _toDisplayString(_unref(stayEnd)), 1))
      : _createCommentVNode("", true),
    (_ctx.columns.validationDate)
      ? (_openBlock(), _createElementBlock("td", _hoisted_12, _toDisplayString(_unref(validationDate)), 1))
      : _createCommentVNode("", true),
    (_ctx.columns.stayDuration)
      ? (_openBlock(), _createElementBlock("td", _hoisted_13, _toDisplayString(_ctx.stay.stayDuration), 1))
      : _createCommentVNode("", true),
    (_ctx.columns.countSavedSearches)
      ? (_openBlock(), _createElementBlock("td", _hoisted_14, _toDisplayString(_unref(searches).length), 1))
      : _createCommentVNode("", true),
    (_ctx.columns.savedSearches)
      ? (_openBlock(), _createElementBlock("td", {
          key: 14,
          title: _unref(searches).join('\n'),
          class: "saved-searches-column"
        }, _toDisplayString(_unref(searches).join(' -- ')), 9, _hoisted_15))
      : _createCommentVNode("", true),
    (_ctx.columns.status)
      ? (_openBlock(), _createElementBlock("td", _hoisted_16, [
          _createElementVNode("span", null, _toDisplayString(_unref(statusLabels)[_ctx.stay.status] ? _unref(statusLabels)[_ctx.stay.status] : _ctx.stay.status), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.columns.seen)
      ? (_openBlock(), _createElementBlock("td", _hoisted_17, _toDisplayString(_unref(displayedSeenStatus)), 1))
      : _createCommentVNode("", true),
    (_ctx.columns.externalPmsiStatus)
      ? (_openBlock(), _createElementBlock("td", _hoisted_18, [
          _createElementVNode("span", null, _toDisplayString(_unref(ExternalPmsiStatusLabel)[_ctx.stay.externalPmsiStatus] || _ctx.stay.externalPmsiStatus || ''), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.columns.comment)
      ? (_openBlock(), _createElementBlock("td", {
          key: 18,
          title: _ctx.stay.comment
        }, [
          _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.stay.comment), 1)
        ], 8, _hoisted_19))
      : _createCommentVNode("", true),
    _createElementVNode("td", {
      onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
      onMouseup: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["middle","stop"]))
    }, [
      (_ctx.allowSelection)
        ? (_openBlock(), _createElementBlock("input", {
            key: 0,
            checked: _ctx.selected,
            type: "checkbox",
            onChange: toggleSelectedStay
          }, null, 40, _hoisted_21))
        : _createCommentVNode("", true)
    ], 32)
  ], 34))
}
}

})