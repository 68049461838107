import _ from 'lodash'

import Api from '@/misc/api'
import { getFullname } from '@/misc/PersonHelper'
import { formatMainContent, selectMainContent } from '@/misc/stay/helper'
import { refreshActs, refreshGroupingResult } from '@/misc/stay/mco/helper'
import { asyncStayParsing, removeSearchDuplicata, simpleParseStay } from '@/misc/textWorkerHelper'
import { PredictedLabelType } from '@/store/modules/diagnosis/types'
import { ModeEnum } from '@/store/modules/settings/types'

import { getPredictedAssociatedDiags, getPredictedRelatedDiags } from './getPredictedDiags.js'

const hiddenAdministrativeInfo = (sessionStorage.getItem('hiddenAdministrativeInfo') ?? '1') === '1'
const suggestedDasDisplayMode = sessionStorage.getItem('suggestedDasDisplayMode') ?? 'list'

const updateDisplayableDiagnoses = (rum, mode) => {
  const diagnosisGroup = (mode === ModeEnum.AUTOMATION) ? rum.automationModeDiagnosisGroup : rum.standardModeDiagnosisGroup

  const displayablePredictedLabels = []
  displayablePredictedLabels.push(rum.predictedLabels.filter((d) => d.type === PredictedLabelType.DP))
  displayablePredictedLabels.push(getPredictedRelatedDiags(diagnosisGroup, rum.predictedLabels))
  if(mode !== ModeEnum.AUTOMATION) {
    displayablePredictedLabels.push(getPredictedAssociatedDiags(diagnosisGroup, rum.predictedLabels))
  }
  displayablePredictedLabels.push(rum.predictedLabels.filter((d) => d.type === PredictedLabelType.MEDICAL_ACTS))
  return {
    ...rum,
    displayablePredictedLabels: _.flatten(displayablePredictedLabels),
  }
}

const updateDisplayableChronicDas = (rums, stayChronicDas) => {
  // Get each rum das array, merge them into one das array and filter null elements
  const dasInStay = rums.map(
    (rum) => {
      const dasList = _.get(rum, 'standardModeDiagnosisGroup.associatedDiags', [])

      return dasList.map((das) => _.get(das, 'associatedDiagnosis.reference', null))
    }
  ).reduce(
    (merged, tomerge) => {
      merged.push(...tomerge)

      return merged
    }
  ).filter((das) => das)

  // Then use this list to remove corresponding chronicDas
  stayChronicDas.chronicDasList = stayChronicDas.chronicDasList.filter(
    (chronicDas) => !dasInStay.includes(_.get(chronicDas, 'diagnosis.reference', null))
  )

  return stayChronicDas
}

const stayProcess = (stay, mode) => {
  const chronicDasJustification = {}
  if (_.get(stay, 'stay.patient.chronicAssociatedDiagnoses', []).length > 0) {
    // eslint doesn't like variable assignment before the if (issues on that were rejected :/)
    // eslint-disable-next-line guard-for-in
    for (const chronicDasIndex in stay.patient.chronicAssociatedDiagnoses) {
      const chronicDas = _.get(stay, `patient.chronicAssociatedDiagnoses[${chronicDasIndex}]`, {})
      if (!chronicDas.diagnosis) {
        continue
      }
      chronicDasJustification[chronicDas.diagnosis.reference]
        = chronicDasJustification[chronicDas.diagnosis.reference]
          ? chronicDasJustification[chronicDas.diagnosis.reference]
          : []
    }
  }
  const suggestedChronicDas = updateDisplayableChronicDas(
    stay.rums,
    {
      patientId: stay.patient.id,
      chronicDasList: _.get(stay, 'patient.chronicAssociatedDiagnoses', []),
      chronicDasJustification: chronicDasJustification,
    }
  )
  return {
    ...stay,
    suggestedChronicDas: suggestedChronicDas,
    rums: _.map(stay.rums, (rum) => ({
      ...updateDisplayableDiagnoses(rum, mode),
    })),
  }
}

const updateFalseJustificationChunks = (displayedStay, reportId, healthEntryId, hintStart, hintEnd, isCreation) => {
  let docType, docId
  if (reportId) {
    docType = 'reports'
    docId = reportId
  } else if (healthEntryId) {
    docType = 'textualHealthEntries'
    docId = healthEntryId
  }

  // Update the chunks in the documents
  _.forEach(displayedStay[docType], (item) => {
    if (item.id === docId) {

      _.forEach(item.chunks, (c) => {
        if (c.chunkStart >= hintStart && c.chunkEnd <= hintEnd) {
          c.isFalseJustification = isCreation
        }
      })
    }
  })

  // Update the chunks for single savedSearches, savedSearchIntersections and additionnalKeywords
  _.forEach(['staySavedSearches', 'savedSearchIntersectionChunks'], (chunkSource) => {
    _.forEach(displayedStay[chunkSource], (chunkAggregate) => {
      if (!chunkAggregate.chunks[docType][docId]) {
        return
      }
      _.forEach(chunkAggregate.chunks[docType][docId], (c) =>  {
        if (c.chunkStart >= hintStart && c.chunkEnd <= hintEnd) {
          c.isFalseJustification = isCreation
        }
      })
    })
  })

  _.forEach(displayedStay['additionnalKeywordChunks'], (chunkAggregate) => {
    if (!chunkAggregate[docType][docId]) {
      return
    }
    _.forEach(chunkAggregate[docType][docId], (c) => {
      if (c.chunkStart >= hintStart && c.chunkEnd <= hintEnd) {
        c.isFalseJustification = isCreation
      }
    })
  })

  // Update the chunks for single predictedLabels
  _.forEach(displayedStay.rums, (rum) => {
    _.forEach(rum.displayablePredictedLabels, (dLabel) => {
      _.forEach(dLabel.chunks[docType][docId], (c) => {
        if (c.chunkStart >= hintStart && c.chunkEnd <= hintEnd) {
          c.isFalseJustification = isCreation
        }
      })
    })
  })
}

export default {
  state: {
    updateSelfRequest: { fetching: false, error: null, ok: false },
    originalStay: null, // This is the stay as it's returned from the API
    displayedStay: null, // This is the stay after the calculations (hints, dates loaded with dayjs, etc...)
    mainContent: {
      type: null,
      drugEventPrestationType: null,
      targetId: 0,
    },
    diagnosisSearchResults: {
      DP: [],
      DR: [],
      DAS: [],
      FPP: [],
      MMP: [],
      AE: [],
    },
    shouldScroll: false,
    hiddenAdministrativeInfo,
    suggestedDasDisplayMode,
    getStayRequest: { fetching: false, error: null, ok: false },
    addDiagnosisRequest: {
      DP: { fetching: false, error: null, ok: false },
      DR: { fetching: false, error: null, ok: false },
      DAS: { fetching: false, error: null, ok: false },
    },
    keywordList: [],
    switchDiagnosisRequest: { fetching: false, error: null, ok: false },
    removeDiagnosisRequest: { fetching: false, error: null, ok: false },
    toggleInitialDasRequest:{ fetching: false, error: null, ok: false },
    togglePredictedDasRequest:{ fetching: false, error: null, ok: false },
    searchDiagnosesRequest: {
      DP: { fetching: false, error: null, ok: false },
      DR: { fetching: false, error: null, ok: false },
      DAS: { fetching: false, error: null, ok: false },
      FPP: { fetching: false, error: null, ok: false },
      MMP: { fetching: false, error: null, ok: false },
      AE: { fetching: false, error: null, ok: false },
    },
    getChronicDasJustificationRequest: { fetching: false, error: null, ok: false },
    searchStayRequest: { fetching: false, error: null, ok: false },
    searchStayResults: [],

    updateStayStatusRequest: { fetching: false, error: null, ok: false },
    syncStayToExternalConnectorRequest: { fetching: false, error: null, ok: false },

    createFalseJustificationRequest: { fetching: false, error: null, ok: false },
    deleteFalseJustificationRequest: { fetching: false, error: null, ok: false },

    disableAutomationRequest: { fetching: false, error: null, ok: false },
  },
  mutations: {
    updateDisplayedStay(state, stay) {
      state.displayedStay = removeSearchDuplicata(stay)
    },
    shouldScroll(state, shouldScroll) {
      state.shouldScroll = shouldScroll
    },
    setMainContent(state, { type, drugEventPrestationType = null, targetId }) {
      state.mainContent = formatMainContent({ type, drugEventPrestationType, targetId })
    },
    getStayStart(state) {
      state.getStayRequest = { fetching: true, error: null, ok: false }
    },
    getStaySuccess(state, { stay }) {
      stay = removeSearchDuplicata(stay)
      state.getStayRequest.fetching = false
      state.getStayRequest.ok = true
      state.originalStay = { ...stay }
      state.displayedStay = simpleParseStay(stay)
    },
    getStayError(state, res) {
      state.getStayRequest.fetching = false
      state.getStayRequest.error = res
      state.displayedStay = null
    },

    recomputeDisplayedStay(state) {
      if (state.originalStay) {
        // FIXME this is quite ugly since there are some on-the-fly edits to the stay (falseJustifications for instance)
        state.displayedStay = simpleParseStay(state.originalStay)
      }
    },

    getChronicDasJustificationStart(state) {
      state.getChronicDasJustificationRequest = { fetching: true, error: null, ok: false }
    },
    getChronicDasJustificationSuccess(state, chronicDasJustification) {
      state.getChronicDasJustificationRequest = { fetching: false, error: false, ok: true }
      state.displayedStay.suggestedChronicDas.chronicDasJustification = chronicDasJustification ? chronicDasJustification : {}
      state.originalStay.suggestedChronicDas.chronicDasJustification = chronicDasJustification ? chronicDasJustification : {}
    },
    getChronicDasJustificationError(state, res) {
      state.getChronicDasJustificationRequest.fetching = false
      state.getChronicDasJustificationRequest.error = res
    },

    addDiagnosisStart(state, diagnosisType) {
      state.addDiagnosisRequest[diagnosisType] = { fetching: true, error: null, ok: false }
    },
    addDiagnosisSuccess(state, { stay, diagnosisType }) {
      state.addDiagnosisRequest[diagnosisType] = { fetching: false, error: null, ok: true }
      state.originalStay = { ...stay }
      state.displayedStay = simpleParseStay(stay)
    },
    addDiagnosisError(state, res) {
      state.addDiagnosisRequest[res.diagnosisType] = { fetching: false, error: res, ok: false }
    },
    resetAddDiagnosis(state, diagnosisType) {
      state.addDiagnosisRequest[diagnosisType] = { fetching: false, error: null, ok: false }
    },
    switchDiagnosisStart(state) {
      state.switchDiagnosisRequest = { fetching: true, error: null, ok: false }
    },
    switchDiagnosisSuccess(state) {
      state.switchDiagnosisRequest = { fetching: false, error: null, ok: true }
    },
    switchDiagnosisError(state, res) {
      state.switchDiagnosisRequest = { fetching: false, error: res.message, ok: false }
    },

    // remove DP mutations
    removeDPStart(state) {
      state.removeDPRequest = { fetching: true, error: null, ok: false }
    },
    removeDPSuccess(state, { stay }) {
      stay = removeSearchDuplicata(stay)
      state.removeDPRequest = { fetching: false, error: null, ok: true }
      state.originalStay = { ...stay }
      state.displayedStay = simpleParseStay(stay)
    },
    removeDPError(state, res) {
      state.removeDPRequest = { fetching: false, error: res, ok: false }
    },
    // removeDR mutations
    removeDRStart(state) {
      state.removeDRRequest = { fetching: true, error: null, ok: false }
    },
    removeDRSuccess(state, { stay }) {
      stay = removeSearchDuplicata(stay)
      state.removeDRRequest = { fetching: false, error: null, ok: true }
      state.originalStay = { ...stay }
      state.displayedStay = simpleParseStay(stay)
    },
    removeDRError(state, res) {
      state.removeDRRequest = { fetching: false, error: res, ok: false }
    },
    // removeDAS mutations
    removeDASStart(state) {
      state.removeDASRequest = { fetching: true, error: null, ok: false }
    },
    removeDASSuccess(state, { stay }) {
      stay = removeSearchDuplicata(stay)
      state.removeDASRequest = { fetching: false, error: null, ok: true }
      state.originalStay = { ...stay }
      state.displayedStay = simpleParseStay(stay)
    },
    removeDASError(state, res) {
      state.removeDASRequest = { fetching: false, error: res, ok: false }
    },

    updateMedicalActsSuccess(state, updatedStay) {
      refreshActs(state.originalStay, updatedStay)
      refreshGroupingResult(state.originalStay, updatedStay)
      // caution: for now justifications are not handled
      refreshActs(state.displayedStay, updatedStay)
      refreshGroupingResult(state.displayedStay, updatedStay)
    },

    searchStayStart(state) {
      state.searchStayRequest = { fetching: true, error: null, ok: false }
    },
    searchStaySuccess(state, stays) {
      state.searchStayRequest = { fetching: false, error: false, ok: true }
      state.searchStayResults = stays
    },
    searchStayError(state, res) {
      state.searchStayRequest.fetching = false
      state.searchStayRequest.error = res
    },
    clearSearchStay(state) {
      state.searchStayResults = []
    },

    searchDiagnosesStart(state, diagnosisType) {
      state.searchDiagnosesRequest[diagnosisType] = { fetching: true, error: null, ok: false }
    },
    searchDiagnosesSuccess(state, { diagnosisType, diagnoses }) {
      state.searchDiagnosesRequest[diagnosisType].fetching = false
      state.searchDiagnosesRequest[diagnosisType].ok = true
      state.diagnosisSearchResults[diagnosisType] = diagnoses
    },
    searchDiagnosesError(state, res) {
      state.searchDiagnosesRequest[res.diagnosisType].fetching = false
      state.searchDiagnosesRequest[res.diagnosisType].error = res
    },
    clearSearchDiagnoses(state, diagnosisType) {
      state.diagnosisSearchResults[diagnosisType] = []
    },

    commentStayStart(state) {
      state.commentStayRequest = { fetching: true, error: null, ok: false }
    },
    commentStaySuccess(state, stay) {
      state.commentStayRequest = { fetching: false, ok: true }
      state.displayedStay.comment = stay.comment
    },
    commentStayError(state, res) {
      state.commentStayRequest.fetching = false
      state.commentStayRequest.error = res
    },

    updateStayStatusStart(state) {
      state.updateStayStatusRequest = { fetching: true, error: null, ok: false }
    },
    updateStayStatusSuccess(state, { stay }) {
      if (stay.status === 'validated') {
        stay = removeSearchDuplicata(stay)
      }
      state.updateStayStatusRequest.fetching = false
      state.updateStayStatusRequest.ok = true
      state.originalStay = { ...stay }
      state.displayedStay = simpleParseStay(stay)
    },
    updateStayStatusError(state, res) {
      state.updateStayStatusRequest.fetching = false
      state.updateStayStatusRequest.error = res
    },
    createFalseJustificationsStart(state) {
      state.createFalseJustificationRequest = { fetching: true, error: null, ok: false }
    },
    createFalseJustificationsSuccess(state, falseJustification) {
      state.createFalseJustificationRequest.fetching = false
      state.createFalseJustificationRequest.ok = true

      let reportId, healthEntryId
      if (falseJustification.report) {
        const report = _.find(state.displayedStay.reports, (r) => r.id === falseJustification.report.id)
        reportId = report.id
        report.falseJustifications = [...report.falseJustifications, falseJustification]
      } else if (falseJustification.textualHealthEntry) {
        const healthEntry = _.find(state.displayedStay.textualHealthEntries, (h) => h.id === falseJustification.textualHealthEntry.id)
        healthEntryId = healthEntry.id
        healthEntry.falseJustifications = [...healthEntry.falseJustifications, falseJustification]
      }

      updateFalseJustificationChunks(state.displayedStay, reportId, healthEntryId, falseJustification.hintStart, falseJustification.hintEnd, true)
    },
    createFalseJustificationsError(state, res) {
      state.createFalseJustificationRequest.fetching = false
      state.createFalseJustificationRequest.error = res
    },
    deleteFalseJustificationsStart(state) {
      state.createFalseJustificationRequest = { fetching: true, error: null, ok: false }
    },
    deleteFalseJustificationsSuccess(state, { reportId, healthEntryId, justificationId }) {
      state.createFalseJustificationRequest.fetching = false
      state.createFalseJustificationRequest.ok = true

      let doc
      if (reportId) {
        doc = _.find(state.displayedStay.reports, (r) => r.id === reportId)
      } else if (healthEntryId) {
        doc = _.find(state.displayedStay.textualHealthEntries, (h) => h.id === healthEntryId)
      }
      const { hintStart, hintEnd } = _.find(doc.falseJustifications, (j) => j.id === justificationId)
      doc.falseJustifications = _.filter(doc.falseJustifications, (j) => j.id !== justificationId)
      updateFalseJustificationChunks(state.displayedStay, reportId, healthEntryId, hintStart, hintEnd, false)
    },
    deleteFalseJustificationsError(state, res) {
      state.createFalseJustificationRequest.fetching = false
      state.createFalseJustificationRequest.error = res
    },
    disableAutomationStart(state) {
      state.disableAutomationRequest = { fetching: true, error: null, ok: false }
    },
    disableAutomationSuccess(state, { stay }) {
      stay = removeSearchDuplicata(stay)
      state.disableAutomationRequest = { fetching: false, error: null, ok: true }
      state.originalStay = { ...stay }
      state.displayedStay = simpleParseStay(stay)
    },
    disableAutomationError(state, res) {
      state.disableAutomationRequest = { fetching: false, error: res, ok: false }
    },
    setHiddenAdministrativeInfo(state, hiddenAdministrativeInfo) {
      state.hiddenAdministrativeInfo = hiddenAdministrativeInfo
      sessionStorage.setItem('hiddenAdministrativeInfo', hiddenAdministrativeInfo ? '1' : '0')
    },
    setSuggestedDasDisplayMode(state, mode) {
      state.suggestedDasDisplayMode = mode
      sessionStorage.setItem('suggestedDasDisplayMode', mode)
    },
    syncStayToExternalConnectorStart(state) {
      state.syncStayToExternalConnectorRequest = { fetching: true, error: null, ok: false }
    },
    syncStayToExternalConnectorSuccess(state) {
      state.syncStayToExternalConnectorRequest = { fetching: false, error: null, ok: true }
    },
    syncStayToExternalConnectorError(state, res) {
      state.syncStayToExternalConnectorRequest = { fetching: false, error: res, ok: false }
    },
    syncStayToExternalConnectorReset(state) {
      state.syncStayToExternalConnectorRequest = { fetching: false, error: null, ok: false }
    },
  },
  actions: {
    getStay({ commit, rootState }, { stayId, idType, mode }) {
      idType = _.isString(idType) && idType.length > 0 ? idType : 'legacy'
      return Api.get(
        {
          url: `/api/stays/${idType}/${stayId}`,
          startMut: 'getStayStart',
          successMut: async (stay) => {

            const mainContent = selectMainContent(stay)
            commit('setMainContent', mainContent)

            stay = stayProcess(stay, mode)
            commit('getStaySuccess', { stay })
            try {
              const parsedStay = await asyncStayParsing(stay, rootState.stayList.currentSearch, rootState.settings.appSettings)
              commit('updateDisplayedStay', parsedStay)
            } catch (e) {
              // pass
            }
          },
          errorMut: 'getStayError',
        },
        commit
      )
    },
    async recomputeDisplayedStay({ commit, rootState, state }) {
      commit('recomputeDisplayedStay')
      try {
        const parsedStay = await asyncStayParsing(state.originalStay, rootState.stayList.currentSearch, rootState.settings.appSettings)
        commit('updateDisplayedStay', parsedStay)
      } catch (e) {
        // pass
      }
    },
    getChronicDasJustification({ commit, rootState }, { patientId, chronicDasList }) {
      return Api.post(
        {
          url: '/api/stays/chronic-das-justification',
          body: {
            targetStayEnd: rootState.stayDisplayer.originalStay.stayEnd,
            patientId,
            chronicDasList: chronicDasList.map(
              (chronicDas) => _.get(chronicDas, 'diagnosis.reference', null)
            ).filter(((chronicDasReference) => chronicDasReference))
          },
          startMut: 'getChronicDasJustificationStart',
          successMut: 'getChronicDasJustificationSuccess',
          errorMut: 'getChronicDasJustificationError',
        },
        commit
      )
    },
    resetAddDiagnosis({ commit }, diagnosisType) {
      commit('resetAddDiagnosis', diagnosisType)
    },
    addDiagnosis({ commit, rootState }, { diagnosisType, rumId, reference, justifications, mode }) {
      return Api.post(
        {
          url: '/api/manual-diagnosis/standard-mode',
          body: { rumId: rumId, diagnosisType, diagnosis: reference, justifications },
          startMut: () => commit('addDiagnosisStart', diagnosisType),
          successMut: async (stay) => {
            stay = stayProcess(stay, mode)
            commit('addDiagnosisSuccess', { stay, diagnosisType })
            try {
              const parsedStay = await asyncStayParsing(stay, rootState.stayList.currentSearch, rootState.settings.appSettings)
              commit('updateDisplayedStay', parsedStay)
            } catch (e) {
              // pass
            }
          },
          errorMut: (res) => commit('addDiagnosisError', { ...res, diagnosisType }),
        },
        commit
      )
    },
    commentStay({ commit }, { stayId, comment }) {
      return Api.post(
        {
          url: `/api/stays/comment/${stayId}`,
          body: { comment },
          startMut: 'commentStayStart',
          successMut: 'commentStaySuccess',
          errorMut: 'commentStayError'
        },
        commit
      )
    },
    switchDiagnosis({ commit, rootState }, { rumId, origin, destination, mode }) {
      return Api.post(
        {
          url: '/api/manual-diagnosis/switch-diagnosis',
          body: { rumId, origin, destination },
          startMut: () => commit('switchDiagnosisStart'),
          successMut: async (stay) => {
            stay = stayProcess(stay, mode)
            commit('switchDiagnosisSuccess')
            try {
              const parsedStay = await asyncStayParsing(stay, rootState.stayList.currentSearch, rootState.settings.appSettings)
              commit('updateDisplayedStay', parsedStay)
            } catch (e) {
              // pass
            }
          },
          errorMut: (res) => commit('switchDiagnosisError', res),
        },
        commit
      )
    },
    // TODO: refacto the various stay processing steps that occur in each of these removeDP/DR/DAS actions
    removeDP({ commit, rootState }, { rumId, mode }) {
      return Api.del(
        {
          url: `/api/manual-diagnosis/dp/${rumId}`,
          startMut: 'removeDPStart',
          successMut: async (stay) => {
            stay = stayProcess(stay, mode)
            commit('removeDPSuccess', { stay })
            try {
              const parsedStay = await asyncStayParsing(stay, rootState.stayList.currentSearch, rootState.settings.appSettings)
              commit('updateDisplayedStay', parsedStay)
            } catch (e) {
              // pass
            }
          },
          errorMut: 'removeDPError',
        },
        commit
      )
    },
    removeDR({ commit, rootState }, { rumId, mode }) {
      return Api.del(
        {
          url: `/api/manual-diagnosis/dr/${rumId}`,
          startMut: 'removeDRStart',
          successMut: async (stay) => {
            stay = stayProcess(stay, mode)
            commit('removeDRSuccess', { stay })
            try {
              const parsedStay = await asyncStayParsing(stay, rootState.stayList.currentSearch, rootState.settings.appSettings)
              commit('updateDisplayedStay', parsedStay)
            } catch (e) {
              // pass
            }
          },
          errorMut: 'removeDRError',
        },
        commit
      )
    },
    removeDAS({ commit, rootState }, { diagId, mode }) {
      return Api.del(
        {
          url: `/api/manual-diagnosis/das/${diagId}`,
          startMut: 'removeDASStart',
          successMut: async (stay) => {
            stay = stayProcess(stay, mode)
            commit('removeDASSuccess', { stay })
            try {
              const parsedStay = await asyncStayParsing(stay, rootState.stayList.currentSearch, rootState.settings.appSettings)
              commit('updateDisplayedStay', parsedStay)
            } catch (e) {
              // pass
            }
          },
          errorMut: 'removeDASError',
        },
        commit
      )
    },
    searchDiagnoses({ commit }, { diagnosisType, search, signal, searchByType = false }) {
      return Api.post(
        {
          url: '/api/stays/search-diagnosis',
          body: searchByType ? { search, diagnosisType } : { search },
          signal,
          startMut: () => commit('searchDiagnosesStart', diagnosisType),
          successMut: (diagnoses) => commit('searchDiagnosesSuccess', { diagnosisType, diagnoses }),
          errorMut: (res) => commit('searchDiagnosesError', { ...res, diagnosisType }),
        },
        commit
      )
    },
    searchStay({ commit }, { search, pmsiType }) {
      return Api.post(
        {
          url: `/api/stays/${pmsiType}/search-stay`,
          body: { search },
          startMut: 'searchStayStart',
          successMut: 'searchStaySuccess',
          errorMut: 'searchStayError',
        },
        commit
      )
    },
    updateStayStatus({ commit, rootState }, { stayId, status, mode }) {
      return Api.post(
        {
          url: '/api/stays/update-status',
          body: { stayId, status, mode },
          startMut: 'updateStayStatusStart',
          successMut: async (stay) => {
            stay = stayProcess(stay, mode)
            commit('updateStayStatusSuccess', { stay })
            try {
              const parsedStay = await asyncStayParsing(stay, rootState.stayList.currentSearch, rootState.settings.appSettings)
              commit('updateDisplayedStay', parsedStay)
            } catch (e) {
              // pass
            }
          },
          errorMut: 'updateStayStatusError',
        },
        commit
      )
    },
    createFalseJustification({ commit }, { errorType, docType, docId, hintStart, hintEnd, report, healthEntry, originType, diagnoses, searches }) {
      return Api.post(
        {
          url: '/api/false-justification/',
          body: { errorType, docType, docId, hintStart, hintEnd, report, healthEntry, originType, diagnoses, searches },
          startMut: 'createFalseJustificationsStart',
          successMut: 'createFalseJustificationsSuccess',
          errorMut: (res) => commit('createFalseJustificationsError', { ...res }),
        },
        commit
      )
    },
    deleteFalseJustification({ commit }, { justificationId, reportId, healthEntryId }) {
      return Api.post(
        {
          url: '/api/false-justification/delete',
          body: { id: justificationId },
          startMut: 'deleteFalseJustificationsStart',
          successMut: () => commit('deleteFalseJustificationsSuccess', { justificationId, reportId, healthEntryId }),
          errorMut: (res) => commit('deleteFalseJustificationsError', { ...res }),
        },
        commit
      )
    },
    disableAutomation({ commit, rootState }, { stayId }) {
      return Api.post(
        {
          url: '/api/manual-diagnosis/disable-automation',
          body: { stayId },
          startMut: 'disableAutomationStart',
          successMut: async (stay) => {
            stay = stayProcess(stay, ModeEnum.AUTOMATION)
            commit('disableAutomationSuccess', { stay })
            try {
              const parsedStay = await asyncStayParsing(stay, rootState.stayList.currentSearch, rootState.settings.appSettings)
              commit('updateDisplayedStay', parsedStay)
            } catch (e) {
              // pass
            }
          },
          errorMut: (res) => commit('disableAutomationError', { ...res }),
        },
        commit
      )
    },
    syncStayToExternalConnector({ commit }, { stayId }) {
      return Api.post(
        {
          url: `/api/stays/sync/${stayId}`,
          body: null,
          startMut: 'syncStayToExternalConnectorStart',
          successMut: () => {
            commit('syncStayToExternalConnectorSuccess')
            setTimeout(() => {
              commit('syncStayToExternalConnectorReset')
            }, 5000)
          },
          errorMut: (res) => {
            commit('syncStayToExternalConnectorError', res)
            setTimeout(() => {
              commit('syncStayToExternalConnectorReset')
            }, 5000)
          },
        },
        commit,
      )
    },
  },
  getters: {
    fullName: (state) => getFullname(state.displayedStay.patient.lastName, state.displayedStay.patient.maidenName, state.displayedStay.patient.firstName)
  }
}
