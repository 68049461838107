import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-1 pr-0" }
const _hoisted_2 = { class: "btn nav-link dropdown-toggle text-nowrap" }

import { computed } from 'vue'
import { RouteLocationRaw } from 'vue-router'

import { useStore } from '@/store'
import { PmsiTypeEnum, pmsiTypesLabels } from '@/store/modules/settings/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'PmsiTypeSelector',
  setup(__props) {

const store = useStore()

const getCurrentLabel = computed(() => pmsiTypesLabels[store.getters.pmsiType])

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_dropdown = _resolveComponent("v-dropdown")!
  const _directive_close_popper = _resolveDirective("close-popper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_dropdown, {
      placement: "bottom",
      class: "popover-container"
    }, {
      popper: _withCtx(() => [
        _createElementVNode("div", null, [
          _withDirectives((_openBlock(), _createBlock(_component_router_link, {
            class: "dropdown-item",
            "active-class": 'active',
            to: {name: 'home', params: {pmsiType: _unref(PmsiTypeEnum).MCO}}
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode(" Médecine, Chirurgie, Obstétrique (MCO) ")
            ])),
            _: 1
          }, 8, ["to"])), [
            [_directive_close_popper]
          ])
        ]),
        _createElementVNode("div", null, [
          _withDirectives((_openBlock(), _createBlock(_component_router_link, {
            class: "dropdown-item",
            "active-class": 'active',
            to: {name: 'home', params: {pmsiType: _unref(PmsiTypeEnum).SSR}}
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode(" Soins Médicaux et de Réadaptation (SMR) ")
            ])),
            _: 1
          }, 8, ["to"])), [
            [_directive_close_popper]
          ])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("button", _hoisted_2, _toDisplayString(getCurrentLabel.value), 1)
      ]),
      _: 1
    })
  ]))
}
}

})