import { onBeforeRouteLeave } from 'vue-router'

import { useStore } from '@/store'

/**
 * Handle reading list mode while navigating
 */
export function useReadingListMode(keepingRouteName: string) {
  const store = useStore()

  onBeforeRouteLeave((to, from) => {
    if (from.query.readingList !== '1') {
      return
    }

    if (to.name === keepingRouteName) {
      return
    }

    store.commit('resetReadingList')
  })
}