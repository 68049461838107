import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row no-gutters align-items-center"
}
const _hoisted_2 = {
  key: 1,
  class: "col"
}
const _hoisted_3 = { class: "col-auto" }
const _hoisted_4 = {
  key: 4,
  class: "col"
}

import { computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { useStore } from '@/store'
import { ModeEnum } from '@/store/modules/settings/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'ReadingListNavigator',
  props: {
    currentStayId: {},
    mode: {},
    baseStayDisplayerPath: {}
  },
  setup(__props: any) {

const props = __props

const router = useRouter()

const store = useStore()
const route = useRoute()
const readingList = computed(() => store.state.stayList.readingList)

onMounted(() => initializeReadingList(props.currentStayId))
async function initializeReadingList(stayId: number) {
  const isReadingList = route.query.readingList === '1'
  if (!isReadingList) {
    return
  }

  await store.dispatch('getCurrentReadingList')

  const readingListStayIds = readingList.value.stayIds
  const stayIdx = readingListStayIds.indexOf(stayId)
  if (stayIdx !== -1) {
    await store.dispatch('navigateReadingList', stayIdx)
  } else {
    await router.replace({
      path: `/id/${stayId}`,
      query: { mode: props.mode }
    })
  }
}

function navigateReadingList(newIndex) {
  const readingListStayIds = readingList.value.stayIds
  const stayId = readingListStayIds[newIndex]

  if (stayId) {
    router.push({
      path: `${props.baseStayDisplayerPath}/${stayId}`,
      query: {
        readingList: 1,
        mode: props.mode,
      }
    })
    store.dispatch('navigateReadingList', newIndex)
  }
}

function endReadingList() {
  store.commit('resetReadingList')
  router.push({ name: 'home', params: { pmsiType: store.getters.pmsiType } })
}

return (_ctx: any,_cache: any) => {
  return (readingList.value.currentIndex !== null && readingList.value.stayIds.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (readingList.value.currentIndex > 0)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "col text-right",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (navigateReadingList(readingList.value.currentIndex - 1)))
            }, _cache[2] || (_cache[2] = [
              _createElementVNode("div", { class: "btn btn-link" }, " ←  Précédent ", -1)
            ])))
          : (_openBlock(), _createElementBlock("div", _hoisted_2)),
        _createElementVNode("div", _hoisted_3, " Séjour " + _toDisplayString(readingList.value.currentIndex + 1) + " / " + _toDisplayString(readingList.value.stayIds.length), 1),
        (readingList.value.currentIndex < readingList.value.stayIds.length - 1)
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              class: "col",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (navigateReadingList(readingList.value.currentIndex + 1)))
            }, _cache[3] || (_cache[3] = [
              _createElementVNode("div", { class: "btn btn-link" }, " Suivant  → ", -1)
            ])))
          : (readingList.value.currentIndex === readingList.value.stayIds.length - 1)
            ? (_openBlock(), _createElementBlock("div", {
                key: 3,
                class: "col",
                onClick: endReadingList
              }, _cache[4] || (_cache[4] = [
                _createElementVNode("div", { class: "btn btn-link" }, " Terminer ", -1)
              ])))
            : (_openBlock(), _createElementBlock("div", _hoisted_4))
      ]))
    : _createCommentVNode("", true)
}
}

})