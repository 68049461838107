import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-auto mr-auto mb-0" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col" }
const _hoisted_7 = {
  key: 1,
  class: "col-form-label"
}
const _hoisted_8 = { class: "ml-2 cp" }
const _hoisted_9 = { class: "mb-0" }
const _hoisted_10 = {
  key: 0,
  class: "row"
}
const _hoisted_11 = { class: "col" }
const _hoisted_12 = {
  key: 1,
  class: "row"
}
const _hoisted_13 = { class: "col" }
const _hoisted_14 = ["value"]
const _hoisted_15 = { class: "row mt-3" }
const _hoisted_16 = { class: "col-auto ml-auto" }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = {
  key: 1,
  class: "loader loader-xs mx-4"
}
const _hoisted_19 = {
  key: 0,
  class: "col-12"
}

import { DatePicker, OcticonButton, SancareModal, SancareOcticon } from '@sancare/ui-frontend-commons'
import dayjs, { Dayjs } from 'dayjs'
import { computed, onMounted, Ref, ref, watch } from 'vue'

import CompletionFilter from '@/filters/CompletionFilter.vue'
import FiltersData from '@/filters/FiltersData'
import { useStore } from '@/store'
import { CompletionFilterItem } from '@/store/modules/filters/types'
import { BaseAct, BaseCodedAct, lateralityOptions, ToBeAddedAct } from '@/store/modules/medical-act/types'
import { CountryEnum, PmsiTypeEnum } from '@/store/modules/settings/types'
import { BaseSummaryUnit } from '@/store/modules/stay/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'MedicalActForm',
  props: {
    preSelectedMedicalAct: { default: null },
    summaryUnit: {},
    pmsiCountry: { default: CountryEnum.FR },
    pmsiType: {},
    presenceDays: { default: '1111111' },
    visible: { type: Boolean }
  },
  emits: ['close-form'],
  setup(__props: any, { emit: __emit }) {

const store = useStore()
const emit = __emit
const props = __props

const completionVisible = ref(false)
const newCodedActMsg = ref('')
const newCodedActMsgContext = ref('')
const newCodedActDescription = ref('')

const actFilterData = computed(() => Object.assign(FiltersData.medicalAct, { subFilters: [] }))
const isAddingCodedAct = computed(() => store.state.medicalAct.requests.addMedicalAct.fetching)
const newActExecutionDate = computed(() => dayjs(newCodedAct.value.executionDate))
const absenceDays = computed(() => {
  const absenceDays = []
  let currentDay = dayjs(props.summaryUnit.startDate)
  for (const presence of props.presenceDays) {
    if (presence === '0') {
      absenceDays.push(currentDay.clone())
    }
    currentDay = currentDay.add(1, 'd')
  }
  return absenceDays
})
const firstPresenceDay = computed(() => {
  let currentDay = dayjs(props.summaryUnit.startDate)
  for (const presence of props.presenceDays) {
    if (presence === '1') {
      return currentDay
    }
    currentDay = currentDay.add(1, 'd')
  }
  return null
})
const newCodedAct = ref(getEmptyNewCodedAct()) as Ref<ToBeAddedAct>

async function addAct() {
  if (isAddingCodedAct.value) {
    return
  }
  if (props.pmsiCountry === CountryEnum.FR) {
    if (newCodedAct.value.reference === '') {
      newCodedActMsg.value = 'Aucune référence sélectionnée'
      newCodedActMsgContext.value = 'danger'
      return
    }
    if (!newCodedAct.value.activity) {
      newCodedActMsg.value = 'Aucune activité sélectionnée'
      newCodedActMsgContext.value = 'danger'
      return
    }
  } else {
    if (!newCodedAct.value.laterality && newCodedAct.value.laterality !== '') {
      newCodedActMsg.value = 'Aucune latéralité sélectionnée'
      newCodedActMsgContext.value = 'danger'
      return
    }
  }
  newCodedActMsg.value = ''
  const startMut = {
    name: props.pmsiType === PmsiTypeEnum.SSR ? 'ssrStay/startRefreshingGroupingResult' : null
  }
  const successMut = {
    name: props.pmsiType === PmsiTypeEnum.SSR ? 'ssrStay/updateMedicalActsSuccess' : 'updateMedicalActsSuccess',
  }
  await store.dispatch('medicalAct/addMedicalAct', {
    body: {
      ...newCodedAct.value,
    },
    urlParams: {
      pmsiType: props.pmsiType,
      summaryUnitId: props.summaryUnit.id,
    },
    startMut,
    successMut,
  })
  if (store.state.medicalAct.requests.addMedicalAct.ok) {
    resetNewCodedAct()
    closeModal()
    return
  }
  newCodedActMsg.value = store.state.medicalAct.requests.addMedicalAct.error.message
  newCodedActMsgContext.value = 'danger'
}
function chooseAct(item: CompletionFilterItem) {
  newCodedActMsg.value = ''
  newCodedAct.value.reference = item.value
  newCodedActDescription.value = item.description
  completionVisible.value = false
}
function getEmptyNewCodedAct(): ToBeAddedAct {
  return {
    reference: '',
    executionDate: dayjs(firstPresenceDay.value).format('YYYY-MM-DD'),
    justifications: null,
  }
}
function resetNewCodedAct() {
  completionVisible.value = true
  newCodedAct.value = getEmptyNewCodedAct()
}
function setExecutionDate(executionDate: Dayjs) {
  newCodedAct.value.executionDate = executionDate.format('YYYY-MM-DD')
}

function closeModal() {
  emit('close-form')
  newCodedActMsg.value = ''
}

function preSelectMedicalAct() {
  newCodedAct.value.reference = props.preSelectedMedicalAct.reference
  newCodedActDescription.value = props.preSelectedMedicalAct.description
  completionVisible.value = false
}

watch(() => props.preSelectedMedicalAct, () => {
  if (props.preSelectedMedicalAct) {
    preSelectMedicalAct()
  } else {
    newCodedAct.value.reference = ''
    newCodedActDescription.value = ''
    completionVisible.value = true
  }
})

onMounted(() => {
  resetNewCodedAct()
  if (props.preSelectedMedicalAct) {
    preSelectMedicalAct()
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(SancareModal), {
    open: _ctx.visible,
    onCloseModal: _cache[4] || (_cache[4] = ($event: any) => (closeModal()))
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t('medicalAct.add')), 1),
        _createVNode(_unref(OcticonButton), {
          class: "col-auto",
          style: {"font-size":"1rem"},
          "octicon-name": "x",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (closeModal()))
        })
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _cache[5] || (_cache[5] = _createElementVNode("label", { class: "col-2 col-form-label text-right font-weight-bold" }, "Date :", -1)),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_unref(DatePicker), {
            "model-value": newActExecutionDate.value,
            "min-date": _unref(dayjs)(_ctx.summaryUnit.startDate),
            "max-date": _unref(dayjs)(_ctx.summaryUnit.endDate),
            "disabled-dates": absenceDays.value,
            "onUpdate:modelValue": setExecutionDate
          }, null, 8, ["model-value", "min-date", "max-date", "disabled-dates"])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _cache[6] || (_cache[6] = _createElementVNode("label", { class: "col-2 col-form-label text-right font-weight-bold" }, "Référence :", -1)),
        _createElementVNode("div", _hoisted_6, [
          (completionVisible.value)
            ? (_openBlock(), _createBlock(CompletionFilter, {
                key: 0,
                class: "mb-2",
                "pmsi-type": _ctx.pmsiType,
                "filter-data": actFilterData.value,
                onAddFilter: chooseAct
              }, null, 8, ["pmsi-type", "filter-data"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("strong", null, _toDisplayString(newCodedAct.value.reference), 1),
                _createElementVNode("span", _hoisted_8, [
                  _createVNode(_unref(SancareOcticon), {
                    name: "pencil",
                    width: 15,
                    height: 15,
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (completionVisible.value = true))
                  })
                ]),
                _createElementVNode("p", _hoisted_9, _toDisplayString(newCodedActDescription.value), 1)
              ]))
        ])
      ]),
      (_ctx.pmsiCountry === _unref(CountryEnum).FR)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _cache[7] || (_cache[7] = _createElementVNode("label", { class: "col-2 col-form-label text-right font-weight-bold" }, "Activité :", -1)),
            _createElementVNode("div", _hoisted_11, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((newCodedAct.value.activity) = $event)),
                type: "number",
                min: "1",
                max: "4"
              }, null, 512), [
                [_vModelText, newCodedAct.value.activity]
              ])
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _cache[8] || (_cache[8] = _createElementVNode("label", { class: "col-2 col-form-label text-right font-weight-bold" }, "Latéralité :", -1)),
            _createElementVNode("div", _hoisted_13, [
              _withDirectives(_createElementVNode("select", {
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((newCodedAct.value.laterality) = $event)),
                class: "form-control col-5"
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(lateralityOptions), (laterality) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: laterality.value,
                    value: laterality.value
                  }, _toDisplayString(laterality.label), 9, _hoisted_14))
                }), 128))
              ], 512), [
                [_vModelSelect, newCodedAct.value.laterality]
              ])
            ])
          ])),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("button", {
            class: "btn btn-primary",
            onClick: addAct
          }, [
            (!isAddingCodedAct.value)
              ? (_openBlock(), _createElementBlock("span", _hoisted_17, "Enregistrer"))
              : (_openBlock(), _createElementBlock("span", _hoisted_18))
          ])
        ]),
        (newCodedActMsg.value !== '')
          ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
              _createElementVNode("span", {
                class: _normalizeClass('text-'+newCodedActMsgContext.value)
              }, _toDisplayString(newCodedActMsg.value), 3)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["open"]))
}
}

})